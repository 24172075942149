import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { GlobalHeader, GlobalFooter } from '@/components/Layouts';
import { useConfigProvider } from '@/context/ConfigProvider';
import classNames from 'classnames';
// import SellOnPietraDialog from '../components/Layouts/SellOnPietraDialog';
import { useToggle } from 'react-use';
import './index.less';
import { AppSettings } from '@/shared/app-common';
import { useTranslation } from 'next-i18next';
import { useUserProvider } from '@/context/UserProvider';
import { fetchHairstyleRecordsBalanceData } from '@/lib/service';

interface LayoutProps {
  /**
   * Please use next/head
   */
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  bgColor?: string;
}

// export async function getServerSideProps() {
//   const res = await fetch(
//     `https://api.pietrastudio.com/commons/business-settings/DISPLAY/CATEGORY_NAV_DROPDOWN_ITEMS`,
//   );
//   console.log('res:', res);
//   return {
//     categoryNavDropdownItems: [],
//   };
// }

const Layout = ({ children, head, showHeader = true, showFooter = true }: LayoutProps) => {
  const { pathname } = useRouter();
  const { isMobile } = useConfigProvider();
  const { isLogin, isShowGiftView, onShowGiftView } = useUserProvider()
  // const [open, toggle] = useToggle(false);
  const { t } = useTranslation('common');
  // const [hasGift, setHasGift] = useState(false);

  const loadBalanceList = () => {
    fetchHairstyleRecordsBalanceData()
      .then((res) => {
        if (res?.code === 0) {
          onShowGiftView(res?.data?.has_survey_reward);
        }
      })
      .catch((e) => {
        // message.error(e?.message);
      });
  };

  useEffect(() => {
    loadBalanceList()
  }, [showHeader, isLogin])

  // app sub title
  const getNavItems = () => {
    return [
      // {
      //   text: t('Home'),
      //   path: '/',
      // },
      {
        text: t('Influencers Square'),
        path: '/influencers',
      },
      {
        text: t('Plan'),
        path: '/plan',
      },
      {
        text: t('Hair Fashion'),
        path: '/blog',
        dropdownItems: [
          {
            text: t('Female'),
            path: '/category/female',
          },
          {
            text: t('Male'),
            path: '/category/male',
          },
        ],
      },
      {
        text: 'FAQ',
        path: '/faq',
      },
      {
        text: 'API',
        path: '/hairpaca-api',
      },
    ];
  };

  return (
    <div className={'layout'}>
      {head}
      {showHeader && (
        <header className={'header'}>
          <GlobalHeader
            isMobile={isMobile}
            isMarketplaceHome={pathname === '/'}
            // hasGift={hasGift}
            navItems={[...getNavItems()]}
          />
          {/* <SellOnPietraDialog isMobile={isMobile} isModalOpen={open} onCancel={toggle} /> */}
        </header>
      )}
      <main
        className={classNames('main', isMobile ? (isShowGiftView ? 'topBanner' : 'mobile') : (isLogin ? '' : 'topBanner'), showHeader ? '' : 'hiddenTopBanner')}
      >
        {children}
      </main>
      {
        showFooter && (
          <footer className={'footer'}>
            <GlobalFooter />
          </footer>
        )
      }
    </div >
  );
};
export default Layout;
